/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../../common/request';
import * as Utils from '../../../common/utils';
import * as Const from '../../../common/const';
import Page from '../../../components/page';
import Button from '../../../components/button';

import './index.less';

import IMG_BANNER from './images/banner.png';
import IMG_AVATAR_DEFAULT from '../../../common/images/avatar-default.png';
import IMG_ENTRANCE_1 from '../../../common/images/entrance1.png';
import IMG_ENTRANCE_2 from '../../../common/images/entrance2.png';
import IMG_ENTRANCE_3 from '../../../common/images/entrance3.png';
import IMG_ENTRANCE_4 from '../../../common/images/entrance4.png';

const PageModel = props => {
	const [ userInfo, setUserInfo ] = useState({});
	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if(!result) return;
		setUserInfo(result.data);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const submitHandle = async () => {
		if(!userInfo.department) {
			Utils._alert('职能部门不能为空哦');
			return;
		}
		if(!userInfo.mobile) {
			Utils._alert('手机号码不能为空哦');
			return;
		}
		if(!userInfo.nickname) {
			Utils._alert('用户名不能为空哦');
			return;
		}

		const result = await Request.post({
			url: '/user/worker/register',
			data: {
				mobile: userInfo.mobile,
				nickname: userInfo.nickname,
				department: userInfo.department,
			}
		});
		if(!result) return;
		Utils._alert('注册成功');
		getUserInfo();
	};

	return (
		<Page {...{ title: '人员注册' }} {...props}>
			<section className="worker-register-container" style={{ minHeight: window.innerHeight }}>
				<div className="title-wrap">完善信息，成为工作人员</div>

				<div className="userinfo">
					<div className="item">
						<label>职能部门</label>
						<div className="input-wrap">
							<select placeholder="请选择职能部门" style={{ color: userInfo.department && Const.departmentMap.includes(userInfo.department) && '#333333' || '#7e7c7c' }} onChange={e => {
								setUserInfo({
									...userInfo,
									department: e.currentTarget.value == '请选择职能部门' ? '' : e.currentTarget.value
								});
							}}>
								<option>请选择职能部门</option>
								{
									Const.departmentMap.map((item, idx) => (
										<option value={item} key={idx}>{item}</option>
									))
								}
							</select>
						</div>
					</div>
					<div className="item">
						<label>姓名</label>
						<div className="input-wrap"><input placeholder="输入姓名" defaultValue={userInfo.nickname || undefined}  onChange={e => {
							setUserInfo({
								...userInfo,
								nickname: e.target.value
							});
						}} /></div>
					</div>
					<div className="item">
						<label>手机号码</label>
						<div className="input-wrap"><input placeholder="输入有效手机号码" defaultValue={userInfo.mobile || undefined} maxLength="11" onChange={e => {
							setUserInfo({
								...userInfo,
								mobile: e.target.value
							});
						}} /></div>
					</div>
				</div>
				<div className="button-wrap"><Button style={{ margin: 15 }} onClick={submitHandle} /></div>
				<div className="tohome-button" onClick={() => props.history.replace('/worker/home')}>回到首页</div>
			</section>
		</Page>
	);
};

export default PageModel;
