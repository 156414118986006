import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

import PageHome from './pages/home';
import PageHot from './pages/hot';
import PageTel from './pages/tel';
import PageTelInfo from './pages/telInfo';
import PageHotInfo from './pages/hotInfo';
import PageReport from './pages/report';
import PageReportDetail from './pages/reportDetail';
import PageScoreDetail from './pages/scoreDetail';
import PageStore from './pages/store';
import PageMsgList from './pages/messageList';
import PageMessage from './pages/message';
import PageMe from './pages/me';
import PageMeSetting from './pages/meSetting';
import PageDynamicDetail from './pages/dynamicDetail';
import PageOrderDetail from './pages/orderDetail';
import PageReportList from './pages/reportList';
import PageOrderList from './pages/orderList';
import PageCommingSoon from './pages/commingSoon';
import PageGuider from './pages/guider';

import PageWorkerHome from './pages/worker/home';
import PageWorkerReportList from './pages/worker/reportList';
import PageWorkerReportDeal from './pages/worker/reportDeal';
import PageWorkerRegister from './pages/worker/register';
import PageWriteOffRecord from './pages/record';

// import IMG_BG_HOME from './common/images/bg/home.jpg';
// import IMG_BG_REPORT from './common/images/bg/report.png';
// import IMG_BG_SCORE_DETAIL from './common/images/bg/scoreDetail.jpg';
// import IMG_BG_MS_LIST from './common/images/bg/msgList.jpg';

// debug
// window.sessionStorage.setItem('token', 'b4a041e2bff3e405c88197860d222034');
// window.sessionStorage.setItem('openid', 'ow1SNwNEpVfLt0Ks5WkX-Ez2-6PQ');

const App = () => (
	<Router>
		<div>
			<Route exact path="/" component={PageHome} />
			<Route exact path="/store" component={PageStore} />
			<Route exact path="/dynamic/:id" component={PageDynamicDetail} />
			<Route exact path="/todo" component={PageCommingSoon} />
			<Route exact path="/guider" component={PageGuider} />
			<Route exact path="/user" component={PageMe} />
			<Route exact path="/user/hot" component={PageHot} />
			<Route exact path="/user/hotInfo" component={PageHotInfo} />
			<Route exact path="/user/tel" component={PageTel} />
			<Route exact path="/user/telInfo" component={PageTelInfo} />
			<Route exact path="/user/report" component={PageReport} />
			<Route exact path="/user/reports" component={PageReportList} />
			<Route exact path="/user/report/:id" component={PageReportDetail} />
			<Route exact path="/message/list" component={PageMsgList} />
			<Route exact path="/user/message" component={PageMessage} />
			<Route exact path="/user/score" component={PageScoreDetail} />
			<Route exact path="/user/orders" component={PageOrderList} />
			<Route exact path="/user/setting" component={PageMeSetting} />
			<Route exact path="/user/order/:id" component={PageOrderDetail} />

			<Route exact path="/worker/home" component={PageWorkerHome} />
			<Route exact path="/worker/reports" component={PageWorkerReportList} />
			<Route exact path="/worker/report-deal/:id" component={PageWorkerReportDeal} />
			<Route exact path="/worker/register" component={PageWorkerRegister} />
			<Route exact path="/write-off/record" component={PageWriteOffRecord}/>
		</div>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

Utils.user.wxLogin({}, () => window.location.reload());
Utils.user.wxsign();
