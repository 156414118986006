/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';

import './index.less';

import IMG_BANNER from './images/banner.png';
import IMG_PRODUCT_DEFAULT from '../../common/images/product-default.png';

const PageModel = props => {
	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/product/list'
		});
		if(!result) return;

		setList(result.data.list || []);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const [ flag, setFlag ] = useState(false);
	const [ obj, setObj ] = useState({});

	const submitHandle = async () => {
		const result = await Request.post({
			url: '/product/exchange',
			data: {
				id: obj.id
			}
		});
		if(!result) return;
		Utils._alert('兑换成功');
		window.location.href = '/user/orders';
	};

	return (
		<Page {...{ page: 'store', showFooter: false, title: '积分商城' }} {...props}>
			<section className="store-container">
				<div className="banner-wrap" style={{ backgroundImage: `url(${IMG_BANNER})` }} />
				<div className="exchange-title">积分兑换专区</div>
				<div className="exchange-list">
					{
						list && list.length > 0 && list.map((item, idx) => {
							const cover = item.cover && Const.imgPrefix + item.cover[0] || IMG_PRODUCT_DEFAULT;
							return (
								<div className="exchange-item" key={idx} onClick={() => {
									setFlag(true);
									setObj(item);
								}}>
									<div className="cover" style={{ backgroundImage: `url(${cover})` }}>
										<span>剩余{item.store}件</span>
									</div>
									<div className="info">
										<div className="name">{item.name}</div>
										<div className="value">{item.price}积分</div>
										<div className="history">已兑换{item.sales}件</div>
										<div className="btn">立即兑换</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</section>

			{
				flag && <section className="exchange-container">
					<div className="exchange-wrapper">
						<div className="confirm-close" onClick={() => {
							setFlag(false);
							setObj({});
						}} />
						<div className="confirm-title1">请确认</div>
						<div className="confirm-title2">是否使用<span>{obj.price}</span>积分兑换该商品</div>
						<div className="exchange-bg" style={{ backgroundImage: `url("${obj.cover && Const.imgPrefix + obj.cover[0] || IMG_PRODUCT_DEFAULT}")` }} ></div>
						<div className="exchange-name">{obj.name}</div>
						<div className="exchange-btn" onClick={submitHandle} />
					</div>
				</section>
			}
		</Page>
	);
};

export default PageModel;
