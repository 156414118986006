/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Const from '../../common/const';
import * as Utils from '../../common/utils';
import Page from '../../components/page';

import './index.less';

import IMG_ZAN from '../../common/images/zan.png';
import IMG_READ_COUNT from '../../common/images/read-count.png';
import IMG_POI from '../../common/images/poi.png';
import IMG_PRODUCT_DEFAULT from '../../common/images/product-default.png';

const PageModel = props => {
	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/user/orders'
		});
		if(!result) return;

		setList(result.data.list || []);
	};
	useEffect(() => {
		getList();
	}, [123]);

	return (
		<Page title="兑换订单">
			<section className="order-list-container">
				{
					list && list.length > 0 && list.map((item, idx) => {
						const productInfo = item.productInfo || {};
						return (
							<div className="list-item" key={idx} onClick={() => {
								props.history.push(`/user/order/${item.id}`);
							}}>
								<div className="baseinfo">
									<div className="item"><label>记录编号：</label><span>{item.number}</span></div>
									<div className="item"><label>创建时间：</label><span>{item.createdAt && moment(item.createdAt).format('YYYY.MM.Dd')}</span></div>
								</div>
								<div className="productinfo">
									<div className="content">
										<div className="cover" style={{ backgroundImage: `url(${productInfo.cover && productInfo.cover[0] && Const.imgPrefix + productInfo.cover[0] || IMG_PRODUCT_DEFAULT})` }} />
										<div className="name">{item.productName}</div>
									</div>
									<div className="btn">查看兑换码</div>
								</div>
							</div>
						)
					})
				}
			</section>
		</Page>
	);
};

export default PageModel;
