import React, { useState, useEffect } from 'react'
import {DatePicker, Form, Input, Space, Toast} from 'antd-mobile'
import Button from "../../components/button";
import Page from "../../components/page";
import IMG_CATEGORY from "../../common/images/calendar.png";
import * as Request from '../../common/request';
import './index.less';

const PageModel = props => {
	const now = new Date()
	const [dateValue, setDateValue] = useState(formatDate(now))
	const [visible, setVisible] = useState(false)
	function formatDate(date) {
		/* eslint no-confusing-arrow: 0 */
		const pad = n => n < 10 ? `0${n}` : n;
		const dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
		return `${dateStr}`;
	}
	const [ list, setList ] = useState([]);
	const [ listQuery, setListQuery ] = useState({exchangeTime:dateValue});
	const getList = async () => {
		const result = await Request.get({
			url: '/order/getRecordList',
			data: {
				...listQuery
			}
		});
		if(!result) return;

		// result.data.list.map((item, idx) => {
		// 	item.rowIndex = String(Math.random()) + idx;
		// 	return item;
		// });

		setList([...result.data.list]);
	};
	useEffect(() => {
		getList();
	}, [listQuery]);
	return(
		<Page title="核销记录" className="me-record-page">
			<section className="me-record-container" >
				<div className="category-wrap">
					<div className="label"><span>日期</span></div>
					<div className="value">
						<div className="date" onClick={() => {
							setVisible(true)
						}}>
							<span>
								<input placeholder="请选择日期"
									   value={dateValue}
									   onChange={e => {
										   setDateValue(e.target.value)
									   }}
								/>
								<DatePicker
									visible={visible}
									onClose={() => {
										setVisible(false)
									}}
									precision='day'
									onConfirm={val => {
										setDateValue(formatDate(val))
										setListQuery({exchangeTime:formatDate(val)});
									}}
								/>
							</span><img src={IMG_CATEGORY} />
						</div>
					</div>
				</div>
			</section>
			<section className="me-table-container" >
				<table className="record-table">
					<thead>
						<tr>
							<th>姓名</th>
							<th>兑换商品</th>
							<th>兑换日期</th>
						</tr>
					</thead>
					<tbody>
					{
						list.map((row,idx)=>{
							return(
								<tr key={idx}>
									<td>{row.userName}</td>
									<td>{row.productName}</td>
									<td>{row.collectTime}</td>
								</tr>
							)
						})
					}
					</tbody>
				</table>
			</section>
		</Page>
	);
};

export default PageModel;