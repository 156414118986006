/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';

import './index.less';

const PageModel = props => {
	const [ data, setData ] = useState({});
	const getData = async () => {
		const { id } = props.match.params;
		const result = await Request.get({
			url: `/dynamic/get/${id}`
		});
		if(!result) return;

		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ props.match.params ]);

	return (
		<Page title='动态详情'>
			<section className="dynamic-detail-container">
				<div className="title">{data.title}</div>
				<div className="author-time"><span className="author">{data.author}</span><span className="time">{data.updatedAt && moment(data.updatedAt).format('YYYY-MM-DD HH:mm')}</span></div>
				<div className="content" dangerouslySetInnerHTML={{ __html: data.content }} />
			</section>
		</Page>
	);
};

export default PageModel;