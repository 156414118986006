/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';

import './index.less';

import IMG_AVATAR_DEFAULT from '../../common/images/avatar-default.png';

const PageModel = props => {
	const [ list, setList ] = useState([]);
	const getList = async () => {
		const result = await Request.get({
			url: '/user/score/list'
		});
		if(!result) return;

		if(result.data.list && result.data.list.length > 0) {
			const listMap = {};
			if(result.data.list && result.data.list.length > 0) {
				result.data.list.map(item => {
					const month = moment(item.createdAt).format('YYYY.MM');
					listMap[month] = listMap[month] || [];
					listMap[month].push(item);
				});
			}

			const list = [];
			Object.keys(listMap).map(month => {
				list.push({
					month,
					list: listMap[month]
				});
			});
			setList(list);
		}
	};
	useEffect(() => {
		getList();
	}, [ 123 ]);

	const [ score, setScore ] = useState({ today: 0, total: 0, rest: 0 });
	const getScore = async () => {
		const result = await Request.get({
			url: '/user/score/total'
		});
		if(!result) return;

		setScore({ ...score, ...result.data });
	};
	useEffect(() => {
		getScore();
	}, [123]);

	const [ userInfo, setUserInfo ] = useState({});
	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if(!result) return;
		setUserInfo(result.data);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	return (
		<Page title="积分明细">
			<section className="score-detail-container">
				<div className="summary">
					<div className="score-info">
						<div className="total"><span className="big">{score.rest}</span><span>总积分</span></div>
						<div className="all"><span>累计获得积分</span><span>{score.total}</span></div>
					</div>
					<div className="user-cover" style={{ backgroundImage: `url(${userInfo && userInfo.cover})` }} />
				</div>
				<div className="score-detail-list">
					{
						list && list.length > 0 && list.map((item, idx) => {
							return (
								<div className="score-item" key={idx}>
									<span>{item.month}</span>
									<div className="score-list">
										{
											item.list.map((_item, _idx) => {
												return (
													<div className="item" key={_idx}>
														<div className="item-info"><span className="title">{_item.desc}</span><span className="time">{moment(_item.createdAt).format('YYYY.MM.DD HH:mm:ss')}</span></div>
														<div className={`item-score ${({ 1: 'plus', 2: 'minus' })[_item.type]}`}>{({ 1: '+', 2: '-' })[_item.type]}{_item.value}</div>
													</div>
												)
											})
										}
									</div>
								</div>
							)
						})
					}
				</div>
			</section>
		</Page>
	);
};

export default PageModel;
