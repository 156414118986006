/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { WaterMark, Image,Mask } from 'antd-mobile';
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';
import FilePreview from './file';
import './index.less';
import {message} from "koa/lib/response";
import {_alert, toast} from "../../common/utils";

const PageModel = props => {
	const source = Utils.getSearchByKey('source');
	const [ data, setData ] = useState({});
	const [props1, setProps] = useState('');
	const [show, setShow] = useState(false);
	const [url, setUrl] = useState('');
	const getData = async () => {
		const { id } = props.match.params;
		if(!id) return;

		const result = await Request.get({
			url: `/problem/get/${id}`,
			data: { id }
		});
		if(!result) return;
		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ props.match.params ]);

	const [ categoryList, setCategoryList ] = useState([]);
	const getCategoryList = async () => {
		const result = await Request.get({
			url: '/problem/category/list'
		});
		if(!result) return;

		setCategoryList(result.data.list);
	};
	useEffect(() => {
		getCategoryList();
	}, [ 123 ]);
	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if (!result) return;
		setProps({
			content: result.data?.nickname,
		});
	};

	useEffect(() => {
		getUserInfo();
	}, [123]);
	const [ categoryLabel, setCategoryLabel ] = useState('');
	const getCategoryLabel = () => {
		if(!data.category || categoryList.length == 0) return;
		const pcode = data.category[0];
		const ccode = data.category[1];

		let label = [];
		categoryList.map(item => {
			if(item.code == pcode) {
				label.push(item.label);
			}else if(item.code == ccode) {
				label.push(item.label);
			}
		});
		label = label.join('-');
		setCategoryLabel(label);
	};
	useEffect(() => {
		getCategoryLabel();
	}, [ categoryList, data.category ]);

	let resultImage = [],temp=[];
	if(data && data.result) {
		data.result.map(res => {
			if (res.status == 41 && res.images && res.images.length > 0) {
				temp = res.images;
			}
			if (res.status == 42){
				resultImage = temp;
			}
		});
	}

	const doReview = async (rid) => {
		const result = await Request.post({
			url: `/problem/review/${data.id}`,
			data: { rid }
		});
		if(!result) return;

		Utils._alert('感谢您的评价');
		getData();
	};

	return (
		<Page title="问题详情">
			<WaterMark {...props1} />
			<section className="report-detail-container" style={{ minHeight: window.innerHeight }}>
				{
					[
						{ label: '记录编号', text: data.number },
						{ label: '创建时间', text: data.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm') },
						{ label: '问题描述', text: data.content },
						{ label: '语音描述', src: data.voiceContent, type: 'mp3' },
						{ label: '事件类型', text: categoryLabel },
						{ label: '事件位置', text: data.address },
						{ label: '相关图片', images: data.covers, type:'img' },

						// { label: '处理人员', text: data.processorInfo && data.processorInfo.nickname },
						{ label: '处理时间', text: data.updatedAt && moment(data.updatedAt).format('YYYY-MM-DD HH:mm') },
						{ label: '处理进度',
							text: Const.problemStatusDisplayMap[data.status] + `${data.status == 12 && (() => {
								let str = '';
								if(data.result && data.result.length > 0) {
									const resultInfo = data.result[data.result.length-1];
									if(resultInfo && resultInfo.content) {
										const rawContentArr = resultInfo.content.split('理由说明：');
										if(rawContentArr && rawContentArr[1]) {
											str = '。理由说明：' + rawContentArr[1];
										}
									}
								}
								return str;
							})() || ''}`,
							style: { color: '#021042', fontSize: 13, fontWeight: '500' }
						},
						{ label: '处理结果', images: resultImage, type: 'file' },
						source && source == 'worker' && {
							label: '处理流程', text: data.result, type: 'list',
							render: list => {
								return list.map((lst, idx) => {
									return (
										<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }} key={idx}>
											<label>{lst.time}</label>
											<span style={{ color: '#333333' }}>{lst.content}</span>
										</div>
									)
								})
							}
						} || {}
					].map((item, idx) => {
						if(!item.text && !item.src && !item.images) return null;

						return (
							<div className="detail-item" key={idx}>
								<label>{item.label}：</label>
								{
									item.type == 'img' && <div className="picture-wrap">
										{
											item.images && item.images.length > 0 && item.images.map(imgItem => imgItem && <div className="item" style={{ backgroundImage: `url(${Const.imgPrefix + imgItem})` }} onClick={() => { setUrl(Const.imgPrefix + imgItem);setShow(true)}}/> || null)
										}
									</div>
								}
								{
									item.type == 'file' && <div className="picture-wrap2">
										
										{
											item.images && item.images.length > 0 && item.images.map((imgItem,index) =>
												<>
													{
														((imgItem.split('.')[1] == 'png' || imgItem.split('.')[1] == 'jpg') && imgItem) ? 
														// <a>{Const.imgPrefix + imgItem}</a>
															// <div>{Const.imgPrefix + imgItem}</div>
															<div className="items" style={{ backgroundImage: `url(${Const.imgPrefix + imgItem})` }} onClick={() => { setUrl(Const.imgPrefix + imgItem); setShow(true) }} />
														:
														// <a>ssss1111</a>
														(imgItem && <a className="item" href={`${Const.imgPrefix + imgItem}?go=true`} target="_blank" download="图片" key={imgItem}>附件下载</a>)
													}
												</>)
										}
									</div>
								}
								{
									item.type == 'list' && <div className="list-wrap">{item.render(item.text)}</div> || <span style={item.style}>{item.text}</span>
								}
								{
									item.type == 'mp3' && item.src && <audio src={Const.imgPrefix + item.src} style={{ height: 32, width: '88%' }} controls={true} />
								}
							</div>
						)
					})
				}

				{
					data.status == 42 && <div className="reviews-wrap">
						{
							source == 'worker' && <>
								<span>{data.review && '市民已评价如下' || '市民暂未评价'}</span>
							</> || <>
								<span>{data.review && '感谢您的评价与支持' || '请对本次的处理结果进行评价'}</span>
							</>
						}
						<div className="review-item">
							{
								[
									{ cls: 'ri1', selectedCls: 'ri1-selected', id: 1 },
									{ cls: 'ri2', selectedCls: 'ri2-selected', id: 2 },
									{ cls: 'ri3', selectedCls: 'ri3-selected', id: 3 },
								].map((item, idx) => <em key={idx} className={`ri ${data.review == item.id ? item.selectedCls : item.cls}`} onClick={() => {
									if(!data.review) {
										doReview(item.id);
									}
								}} />)
							}
						</div>
					</div>
				}
				{
					(data.status == 33 || data.status == 40) && source == 'worker' && <div className="gochuli-wrap">
						<div className="item" onClick={() => {
							props.history.push(`/worker/report-deal/${data.id}?category=chuli`);
						}}>去处理</div>
						<div className="item" onClick={async () => {
							props.history.push(`/worker/report-deal/${data.id}?category=bohui`);
						}}>驳回</div>
					</div>
				}
				<Mask visible={show} onMaskClick={() => setShow(false)} opacity={0.1}>
					<Image 
						style={{width:'90%',height:'80%',margin:'100px auto'}}
						fit='fill'  src={url}></Image>
				</Mask>
			</section>
		</Page>
	);
};

export default PageModel;
