/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import './index.less';

import IMG_COVER_1 from './images/a.png';
import IMG_COVER_2 from './images/b.png';
import IMG_COVER_3 from './images/c.png';
import IMG_COVER_4 from './images/d.png';
import IMG_COVER_5 from './images/e.png';

const PageModel = props => {
	return (
		<Page title="热线事件">
			<section className="tel-container" style={{ minHeight: window.innerHeight - 50 }}>
				<div className="entrances2">
					{
						[
							{ cover: IMG_COVER_1, bgColor: '#BBE9FF', index: 1, label: '交通管理', link: '/user/telInfo' },
							{ cover: IMG_COVER_2, bgColor: '#BBE9FF', index: 2, label: '环境保护', link: '/user/telInfo' },
							{ cover: IMG_COVER_3, bgColor: '#BBE9FF', index: 3, label: '公共事业', link: '/user/telInfo' },
							{ cover: IMG_COVER_5, bgColor: '#BBE9FF', index: 4, label: '其他诉求', link: '/user/telInfo' },
						].map((item, idx) => {
							return <div className="item" style={item.style} key={idx} onClick={() => {
								if (item.link) {
									Utils.user.setType(item.index)
									Utils.user.setTypeName(item.label)

									props.history.push(item.link);
								}
							}}>
								<div className="cover" style={{ backgroundImage: `url(${item.cover})` }} />
								<div className="title">{item.label}</div>
							</div>
						})
					}
				</div>
			</section>

		</Page>
	);
};

export default PageModel;
