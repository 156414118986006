/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import './index.less';

import IMG_ENTRANCE_COVER_1 from './images/tel.png';
import IMG_ENTRANCE_COVER_2 from './images/tuwen.png';

const PageModel = props => {
	return (
		<Page title="热线事件">
			<section className="hot-container" style={{ minHeight: window.innerHeight - 50 }}>
				<div className="entrances2">
					{
						[
							{ cover: IMG_ENTRANCE_COVER_1, bgColor: '#BBE9FF', index: 1, label: '电话快报', link: '/user/tel' },
							{ cover: IMG_ENTRANCE_COVER_2, bgColor: '#BBE9FF', index: 1,label: '图文上报', link: '/user/hotInfo' },
						].map((item, idx) => {
							return <div className="item block" style={item.style} key={idx} onClick={() => {
								console.log('----------ok-----------');
								let data = item.index;
								Utils.user.setMode(data)

								if (item.link) {
									props.history.push(item.link);
								}
							}}>
								<div className="cover" style={{ backgroundImage: `url(${item.cover})` }} />
							</div>
						})
					}
				</div>
			</section>
		
		</Page>
	);
};

export default PageModel;
