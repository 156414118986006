/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import Page from '../../components/page';

import './index.less';

const PageModel = props => {
	return (
		<Page {...{ page: 'guider', title: '操作指南' }} {...props}>
			<section className="guider-container" style={{ minHeight: window.innerHeight - 50 }}>
				<div className="title">嗨，亲爱的市民朋友，你好</div>
				<div className="title">欢迎使用华新"管达人"社区平台</div>
				<div className="block-title">在这里，您可以</div>
				<div className="txt">1. 发现华新问题，上报案件，一起共建我们的家园</div>
				<div className="txt">2. 发表留言，畅谈社区周边趣事，以及您的宝贵意见和建议</div>
				<div className="txt">3. 并且，因此可以用您所获的的积分，在平台上上兑换喜欢的物品</div>
				<div className="block-title">关于积分兑换的规则和详情</div>
				<div className="txt2 txt21">我们将于近期推出，敬请期待哦～</div>
			</section>
		</Page>
	);
};

export default PageModel;
