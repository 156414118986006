/**
 * Created by jimmy on 2019/11/8.
 */
/**
 * Created by jimmy on 2019/8/13.
 */
import 'whatwg-fetch';
import * as Utils from './utils';
import * as CONST from './const';
import moment from "moment/moment";
import ImageCompressor from "js-image-compressor";
const OSS = require('ali-oss');

export const getHeaders = (conf = {}) => {
	const token = window.sessionStorage.getItem('token');
	const openid = window.sessionStorage.getItem('openid');
	const _header = {
		"content-type":"application/json; charset=utf-8",
		"auth-token": token,
		"auth-openid": openid,
		"sys-token": 'a1s4md6cj7n8d9j0as2dq0weeq1okgmx',
		...conf
	};
	return _header;
};

export const getUploadHeaders = (conf = {}) => {
	const token = window.sessionStorage.getItem('token');
	const openid = window.sessionStorage.getItem('openid');
	const _header = {
		"auth-token": token,
		"auth-openid": openid,
		"sys-token": 'a1s4md6cj7n8d9j0as2dq0weeq1okgmx',
		...conf
	};
	return _header;
};

export const uploadFetch = async (settings = {}) => {
	const url = CONST.apiPrefix + settings.url;
	settings.body = settings.body || settings.data;
	delete settings.url;
	delete settings.data;
	settings.headers = getUploadHeaders(settings.headers);
	let response = await window.fetch(url, settings);
	// if(response.status && response.status === 401) {
	// 	Utils._alert('登录信息过期，请重新登录');
	// 	setTimeout(() => {
	// 		window.location.href = '/login';
	// 	}, 500);
	// 	return;
	// }
	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};

export const compressHelp = ($file,file_name) => {
	return new Promise((resolve) => {
		new ImageCompressor({
			file: $file,
			quality: 0.6,
			maxWidth: 2000,
			maxHeight: 2000,
			width: 1000,
			height: 1000,
			minWidth: 500,
			minHeight: 500,
			convertSize: Infinity,
			loose: true,
			redressOrientation: true,
			// 压缩前回调
			beforeCompress: function (result) {
				// console.log('压缩之前图片尺寸大小: ', result.size);
				// console.log('mime 类型: ', result.type);
			},
			// 压缩成功回调
			success: async function (cresult) {
				cresult = new File([cresult], $file.name);
				resolve(cresult);
			}
		});
	})
};
export const uploadToken = async () =>{
	try {
		let tokenResult =  await get({url: "/problem/upload/token"});
		if(!tokenResult){
			Utils._alert('图片上传异常，Token:'+tokenResult);
		}
		return tokenResult;
	}catch (err){
		Utils._alert('配置信息异常，请联系技术人员反馈：'+err.message);
	}
};
export const uploadOss= async (settings ={},file)=>{
	const ossTokenData = settings.data;
	const client = new OSS({
		// yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
		region: ossTokenData.region,
		// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
		accessKeyId: ossTokenData.accessKeyId,
		accessKeySecret: ossTokenData.accessKeySecret,
		// 从STS服务获取的安全令牌（SecurityToken）。
		stsToken: ossTokenData.stsToken,
		// 填写Bucket名称。
		bucket: 'little-butler'
	});
	const [name, suffix] = file.name.split('.');
	let newFileName = `${parseInt(Math.random() * 100)}-${moment().format('YYYYMMDDHHmmss')}`;
 	let fileFullName = 'assets/upload/problem/'+newFileName+'.'+suffix;

	try {
		// 填写Object完整路径。Object完整路径中不能包含Bucket名称。
		// 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
		// data对象可以自定义为file对象、Blob数据或者OSS Buffer。
		Utils.loading.show();
		const result = await client.put(
			fileFullName,
			file
		);
		Utils.loading.hide();
		const response ={
			name:result.name || '',
			url:result.url || ''
		};
		return response;
	} catch (e) {
		Utils.loading.hide();
		Utils._alert("上传图片异常，请截图反馈技术人员："+e.message)
		console.log(e);
	}
};
export const post = async (settings) => {
	let url = CONST.apiPrefix + settings.url;
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers: getHeaders(settings.headers),
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();
	if(response.status && response.status === 401) {
		Utils.user.wxLogin({}, () => window.location.reload());
		return;
	}
	
	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};

export const get = async (settings = {}) => {
	let url = CONST.apiPrefix + settings.url;

	if(settings.data) {
		let queryArr = [];
		for(let i in settings.data) {
			if(i && settings.data[i]){
				queryArr.push(`${i}=${settings.data[i]}`);
			}
		}
		url += '?' + queryArr.join('&');
	}
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'GET',
		headers: getHeaders(settings.headers),
	});
	Utils.loading.hide();
	if(response.status && response.status === 401) {
		Utils.user.wxLogin({}, () => window.location.reload());
		return;
	}

	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};