import React, { useState, useEffect } from 'react';
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import './index.less';

import IMG_ENTRANCE_COVER_1 from './images/teIInfo.png';

const PageModel = props => {
	const [data, setData] = useState({});

	return (
		<Page title="热线事件">
			<section className="telInfo-container" style={{ minHeight: window.innerHeight - 50 }}>
				<div className="entrances2">
					{
						[
							{ name: '白马塘村', index: 1, tel: '39807522' },
							{ name: '北新村', index: 2, tel: '59778886' },
							{ name: '华益村', index: 3, tel: '69799956' },
							{ name: '淮海村', index: 4, tel: '69790376' },
							{ name: '火星村', index: 5, tel: '59778097' },
							{ name: '坚强村', index: 6, tel: '59772460' },
							{ name: '凌家村', index: 7, tel: '59791174' },
							{ name: '陆象村', index: 8, tel: '59797719' },
							{ name: '马阳村', index: 9, tel: '59798399' },
							{ name: '嵩山村', index: 10, tel: '39809366' },
							{ name: '新谊村', index: 11, tel: '69236620' },
							{ name: '秀龙村', index: 12, tel: '69798045' },
							{ name: '徐谢村', index: 13, tel: '59796345' },
							{ name: '叙南村', index: 14, tel: '59770736' },
							{ name: '叙中村', index: 15, tel: '39872717' },
							{ name: '朱长村', index: 16, tel: '69790711' },
							{ name: '周浜村', index: 17, tel: '69790725' },
							{ name: '杨家庄村', index: 18, tel: '59779665' },
							{ name: '新木桥村', index: 19, tel: '59772035' },
							{ name: '宝龙社区居委', index: 20, tel: '59790863' },
							{ name: '春江社区居委', index: 21, tel: '59796033' },
							{ name: '凤溪居委', index: 22, tel: '59776199' },
							{ name: '华府居委', index: 23, tel: '69790361' },
							{ name: '华新居委', index: 24, tel: '59794603' },
							{ name: '华腾居委', index: 25, tel: '69787283' },
							{ name: '悦欣居委', index: 26, tel: '69781591' },
							{ name: '金瑞苑社区居委', index: 27, tel: '69781325' },
							{ name: '瑞和锦庭社区居委', index: 28, tel: '69781073' },
							{ name: '西郊半岛社区居委', index: 29, tel: '59790167' },
							{ name: '新丰社区居委', index: 30, tel: '59796037' },
							{ name: '星尚湾社区居委', index: 31, tel: '69780932' },
							{ name: '悦澜社区居委', index: 32, tel: '59790768' },
							{ name: '华悦社区居委', index: 33, tel: '59790578' },
							{ name: '华强社区居委', index: 34, tel: '59791101' },
						].map((item, idx) => {
							return <div className="item block" style={item.style} key={idx} onClick={async() => {
									
									//埋点记录
								   let data = { 
											 "userId":Utils.user.getInfo(),
											 "mode": window.sessionStorage.getItem('mode'),
											 "type": window.sessionStorage.getItem('type'),
											 "typename": window.sessionStorage.getItem('typename'),
											 "tel": item.index,
											 "telname": item.name,
											 "telcode": item.tel
											}
									console.log('data==',data);
									const result = await Request.post({
										url: '/sends/add',
										data: data
									});
									console.log(result);
									// if (!result) return;

									setTimeout(function () {
										window.open(`${'tel:021' + item.tel}`);
									}, 100);

								}}>
								<div className="cover" style={{ backgroundImage: `url(${IMG_ENTRANCE_COVER_1})` }} >
									<div className="covertitle">{item.name}</div>
									<div className="covertel">021{item.tel}</div>
								</div>
							</div>
						})
					}
				</div>
			</section>

		</Page>
	);
};

export default PageModel;
