/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import QRCode from 'qrcode.react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';

import './index.less';

import IMG_AVATAR_DEFAULT from '../../common/images/avatar-default.png';

const PageModel = props => {
	const [ data, setData ] = useState({});
	const getData = async () => {
		const { id } = props.match.params;
		if(!id) return;

		const result = await Request.get({
			url: `/order/get/${id}`,
			data: { id }
		});
		if(!result) return;
		setData(result.data);
	};
	useEffect(() => {
		getData();
	}, [ props.match.params ]);

	return (
		<Page title="订单详情">
			<section className="order-detail-container">
				<div className="baseinfo">
					{
						[
							{ label: '订单编号', text: data.number },
							{ label: '创建时间', text: data.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss') },
							{ label: '兑换商品', text: data.productName },
							{ label: '订单状态', text: ({ 1: '待领取', 2: '已领取' })[data.status] },
							{ label: '领取时间', text: data.collectTime },
							{ label: '兑换地址', text: data.address },
						].map((item, idx) => {
							return(
								<div className="item" key={idx}><label>{item.label}：</label><span>{item.text}</span></div>
							)
						})
					}
				</div>
				{
					data.status == 1 && <div className="qrcode">
						{ data.number && <QRCode value={`http://little-butler-admin.yeekin.cn/order/write-off/` + data.number} /> }
						<span>领取礼品时请出示二维码</span>
					</div>
				}
			</section>
		</Page>
	);
};

export default PageModel;
