import React from 'react';

class FilePreview extends React.Component {
    render() {
        const { fileUrl, fileType } = this.props;

        if (fileType === 'image') {
            return <img src={fileUrl} alt="预览" />;
        }

        if (fileType === 'pdf') {
            return <embed src={fileUrl} type="application/pdf" width="100%" height="600px" />;
        }

        if (fileType === 'excel' || fileType === 'word') {
            return (
                <iframe
                    src={`${fileUrl}`}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                />
            );
        }

        return null;
    }
}

export default FilePreview;



