/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';

import * as Request from '../../../common/request';
import * as Utils from '../../../common/utils';
import * as Const from '../../../common/const';
import Page from '../../../components/page';

import './index.less';

import IMG_ZAN from '../../../common/images/zan.png';
import IMG_READ_COUNT from '../../../common/images/read-count.png';
import IMG_POI from '../../../common/images/poi.png';
import IMG_AVATAR_DEFAULT from '../../../common/images/avatar-default.png';

const CONST_TABS = [
	{ label: '全部', status: 9999 },
	{ label: '待处理', status: 33 },
	{ label: '已结案', status: 42 },
];

const PageModel = props => {
	const [ tab, setTab ] = useState({});
	useEffect(() => {
		const status = Utils.getSearchByKey('status');
		let target = CONST_TABS.filter(item => item.status == status);
		target = target && target[0] || CONST_TABS[0];
		setTab(target);
	}, [ window.location.href ]);

	const [ list, setList ] = useState([]);
	const getList = async () => {
		const data = {};
		if(tab.status && tab.status != 9999) {
			data.status = tab.status;
		}
		const result = await Request.get({
			url: '/problem/worker/list',
			data
		});
		if(!result) return;

		setList(result.data.list || []);
	};
	useEffect(() => {
		getList();
	}, [ tab ]);

	return (
		<Page title="问题列表" style={{ minHeight: window.innerHeight }}>
			<section className="worker-report-list-container">
				<div className="tabs">
					{
						CONST_TABS.map((item, idx) => {
							return (
								<div className={`item${ item.status == tab.status ? ' selected' : '' }`} key={idx} onClick={() => {
									props.history.replace(`/worker/reports?status=${item.status}`)
								}}>{item.label}</div>
							)
						})
					}
				</div>
				<div className="list-wrap">
				{
					list && list.length > 0 && list.map((item, idx) => {
						return (
							<div className="list-item" key={idx}>
								<div className="item-wrap" onClick={() => {
									props.history.push(`/user/report/${item.id}?source=worker`);
								}}>
									<div className="item"><label>记录编号：</label><span>{item.number.substr(0, 10)}</span></div>
									<div className="item"><label>创建时间：</label><span>{item.createdAt && moment(item.createdAt).format('YYYY.MM.DD')}</span></div>
								</div>
								<div className="item-wrap">
									<div className="item" onClick={() => {
										props.history.push(`/user/report/${item.id}?source=worker`);
									}}><label>问题描述：</label><span>{item.content || (item.voiceContent && '语音描述，请进入页面收听')}</span></div>
									<div style={{ display: 'flex' }}>
										{
											[33,40].includes(item.status) && <>
												<div className="gochuli" onClick={() => {
													props.history.push(`/worker/report-deal/${item.id}?category=chuli`);
												}}>去处理</div>
												<div className="gochuli" onClick={async () => {
													props.history.push(`/worker/report-deal/${item.id}?category=bohui`);
												}}>驳回</div>
											</> || <div className="status">{Const.problemStatusMap[item.status]}</div>
										}
									</div>
								</div>
								<div className="item-wrap">
									<div className="item"><label style={{ width: 92 }}>截止处理时间：</label><span>{item.processorExpired}{item.status != 42 && item.processorExpired < moment().format('YYYY-MM-DD') && <span style={{ paddingLeft: 8, color: 'red' }}>(已超时)</span>}</span></div>
								</div>
							</div>
						)
					})
				}
				</div>
			</section>
		</Page>
	);
};

export default PageModel;
