import React, { useState, useEffect } from 'react';
import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import * as AMap from '../../common/amap';
import Page from '../../components/page';
import Button from '../../components/button';
import './index.less';
import IMG_CATEGORY from '../../common/images/category.png';
import IMG_POI from '../../common/images/poi.png';
import IMG_TRIAL from '../../common/images/trial.png';
import IMG_RECORD from './images/record.png';
import IMG_PLAY from './images/play.png';
import IMG_PAUSE from './images/pause.png';

const _uploadHelper = (localId) => {
	return new Promise((resolve, reject) => {
		window.wx.uploadVoice({
			localId, // 需要上传的音频的本地ID，由stopRecord接口获得
			isShowProgressTips: 1, // 默认为1，显示进度提示
			success: (res) => {
				resolve(res.serverId);
			},
			error: () => {

			}
		});
	})
}

const PageModel = props => {
	const [localId, setLocalId] = useState(null);
	const [playFlag, setPlayFlag] = useState(false);
	const [recordFlag, setRecordFlag] = useState(false);
	const [timer, setTimer] = useState(0);
	const [formObj, setFormObj] = useState({ selectedCategory: [], covers: ['', '', ''] });
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [categoryList, setCategoryList] = useState([]);
	const [rawCategoryList, setRawCategoryList] = useState([]);
	const [categoryType, setCategoryType] = useState('hotevent');
	const [categoryFlag, setCategoryFlag] = useState(false);
	const [positionFlag, setPositionFlag] = useState(false);
	const [disabled, setDisabled] = useState(false);


	let ctimer = null;
	const countTimer = () => {
		if (!recordFlag) return;
		ctimer = setTimeout(() => {
			setTimer(timer + 1);
		}, 1000);
	};
	useEffect(() => {
		clearTimeout(ctimer);
		ctimer = null;
		countTimer();
	}, [timer, recordFlag]);

	useEffect(() => {
		getPosition();
	}, [123]);

	useEffect(() => {
		getCategoryList();
	}, [categoryType]);

	//数据提交
	const submitHandle = async () => {
		const _formObj = { ...formObj };
		console.log('_formObj-----', _formObj);
		
		if (!_formObj.name && !localId) {
			Utils._alert('请输入名字');
			return;
		}
		if (!_formObj.tel && !localId) {
			Utils._alert('请输入联系方式');
			return;
		}
		if (!_formObj.content && !localId) {
			Utils._alert('请输入描述信息');
			return;
		}
		if (!address2) {
			Utils._alert('请输入发生位置');
			return;
		}
		if (!_formObj.selectedCategory || _formObj.selectedCategory.length < 1) {
			Utils._alert('请选择案件分类');
			return;
		}

		let category = _formObj.selectedCategory;

		const address = `${address1}${address2}`;

		if (address.indexOf('华新') == -1) {
			Utils._alert('您上报的问题不在华新镇行政区域内哦～');
			return;
		}
		if (!!localId) {
			_formObj.voiceContent = await _uploadHelper(localId);
		}

		const submitObj = { ..._formObj, address };
		if (categoryType == 'hotevent') submitObj.category = category;
		submitObj.type = 'hotevent';

		setDisabled(true);
		const result = await Request.post({
			url: '/problem/report',
			data: submitObj
		});
		if (!result) return;

		Utils._alert('问题上报成功，感谢您的反馈');
		
		setTimeout(() => {
			window.history.go(-1);
		}, 2000);
	};

	//图片上传
	const uploadHandle = async (e, idx) => {
		const selectedFile = e.target.files[0];
		let tokenResult = await Request.uploadToken();
		const selectedFileCompress = await Request.compressHelp(selectedFile, selectedFile.name);
		const resultUpload = await Request.uploadOss(tokenResult, selectedFileCompress);
		if (!resultUpload) return;
		const src = resultUpload.name;
		formObj.covers[idx] = src;
		setFormObj({ ...formObj });
	};

	//获取类型列表
	const getCategoryList = async () => {
		const result = await Request.get({
			url: '/problem/category/list',
			data: {
				type: categoryType
			}
		});
		if (!result) return;

		const list = [];
		result.data.list.map(lst => {
			if (lst.parentId == '0') {
				list.push({ ...lst, children: [] });
			}
		});
		setCategoryList(list);
		setRawCategoryList(result.data.list);
		setFormObj({
			...formObj,
			selectedCategory: []
		});
	};

	//案件类型选择
	const getCategoryLabel = () => {
		if (!formObj.selectedCategory || formObj.selectedCategory.length <= 0) return '选择类型';

		const label = [];
		formObj.selectedCategory.map(code => {
			const [target] = rawCategoryList.filter(item => item.code == code);
			if (target && target.label) {
				label.push(target.label);
			}
		});
		return label.join('-');

	};

	//获取地理位置信息
	const getPosition = async () => {
		if (positionFlag) return;
		setPositionFlag(true);
		const result = await AMap.getCurrentPosition2();
		const debugKey = Utils.getSearchByKey('debug');
		if (debugKey && debugKey == 'wuxiaobai') {
			alert(JSON.stringify(result));
		}
		setPositionFlag(false);
		if (result && result.info && result.info.toLocaleLowerCase() == 'success') {
			const address1 = `${result?.province || '上海市'}${result?.city || '上海市'}${result?.district || '青浦区'}${result?.township || '华新镇'}`;
			setAddress1(address1);
			setAddress2(result.formattedAddress);
		} else {
			setAddress1('上海市青浦区华新镇');
		}
	};
	return (
		<Page title="12345热线上报">
			<section className="report-container" style={{ minHeight: window.innerHeight }}>
				{/* 姓名 */}
				<div className="category-wrap">
					<div className="label"><span>姓名</span></div>
					<div className="value"><span style={{ marginLeft: 10, marginRight: 10 }}><input placeholder="输入姓名" defaultValue="" onChange={e => {
						setFormObj({
							...formObj,
							name: e.target.value
						});
					}} /></span></div>
				</div>

				{/* 联系方式 */}
				<div className="category-wrap">
					<div className="label"><span>联系方式</span></div>
					<div className="value"><span style={{ marginLeft: 10, marginRight: 10 }}><input placeholder="输入有效的手机号码" maxLength="11" defaultValue="" onChange={e => {
						setFormObj({
							...formObj,
							tel: e.target.value
						});
					}} /></span></div>
				</div>

				{/* 定位功能 */}
				<div className="poi-wrap">
					<div className="category-wrap">
						<div className="label"><img src={IMG_POI} /><span style={{ paddingRight: 8 }}>事件位置</span><em>{address1}</em></div>
						<div className="reset" onClick={getPosition}>{positionFlag && '定位中…' || '重新定位'}</div>
					</div>
					<div className="textarea-wrap">
						<textarea placeholder="请输入发现问题的详细位置（必填，越详细越容易被处理）" defaultValue={address2} onChange={e => {
							setAddress2(e.target.value);
						}} />
					</div>
				</div>

				{/* 类型选择 */}
				<div className="category-wrap" onClick={() => {
					setCategoryFlag(true);
				}}>
					<div className="label"><span>所属类型</span></div>
					<div className="value"><span style={{ marginLeft: 10, marginRight: 10 }}>{getCategoryLabel()}</span></div>
					<img className="trial" src={IMG_TRIAL} />
				</div>

				{/* 详细描述 */}
				<div className="textarea-wrap">
					<textarea placeholder="您的诉求是什么（不少于15字）" onChange={e => {
						setFormObj({
							...formObj,
							content: e.target.value
						});
					}} />
				</div>

				{/* 图片上传 */}
				<div className="picture-wrapper">
					<span className="picture-wrap-title">请上传1-3张能体现问题的照片</span>
					<div className="picture-wrap">
						{
							formObj && formObj.covers && formObj.covers.length > 0 && formObj.covers.map((item, idx) => {
								if (idx >= 3) return null;

								return (
									<div className="item" key={idx}>
										{item && <img src={Const.imgPrefix + item} />}
										{item && <div className="close" onClick={() => {
											const covers = [...formObj.covers];
											covers.splice(idx, 1);

											for (let i = 0, _len = 3 - covers.length; i < _len; i++) {
												covers.push('');
											}

											setFormObj({ ...formObj, covers });
										}} />}
										<input type="file" accept="image/*" onChange={(e) => {
											uploadHandle(e, idx);
										}} />
									</div>
								)
							})
						}
					</div>
				</div>


				{/* 语音上报 */}
				<div className="voice-wrap">
					<span>您还可以使用语音上报案件</span>
					<div className="record-wrap" onClick={(e) => {
						e.preventDefault();
						if (localId) {
							if (!!playFlag) {
								setPlayFlag(false);
								window.wx.pauseVoice({
									localId
								});
							} else {
								setPlayFlag(true);
								window.wx.playVoice({
									localId
								});
							}
						} else {
							if (!!recordFlag) {
								setRecordFlag(false);
								clearTimeout(ctimer);
								ctimer = null;
								window.wx.stopRecord({
									success: (res) => {
										setLocalId(res.localId);
									}
								});
							} else {
								window.wx.startRecord({
									success: () => {
										setRecordFlag(true);
									}
								});
							}
						}
					}}>
						{
							localId &&
							(playFlag && <em className="stop-play"><img src={IMG_PAUSE} />停止播放录音</em> || <em className="start-play"><img src={IMG_PLAY} />开始播放录音</em>) ||
							(recordFlag && <em className="stop-record">录音中……点击停止录音</em> || <em className="start-record"><img src={IMG_RECORD} />点击开始录音</em>)
						}
					</div>
					{
						localId && <div className="play-wrap" onClick={() => setLocalId(null)}>重新录音</div>
					}
				</div>


				<div className="button-wrap"><Button style={{ margin: 15 }} disabled={disabled} onClick={submitHandle} /></div>
			</section>

			   {/* 类型/部件类型 */}
			{
				categoryFlag && <section className="category-container">
					<section className="category-wrap">
						<div className="title">
							<div className="title-tabs">
								<div className={`item${categoryType == 'hotevent' ? ' active' : ''}`} onClick={() => { setCategoryType('category'); }}>选择类型</div>
							</div>
							<div className="close" onClick={() => { setCategoryFlag(false); }} />
						</div>
						<div className="list-wrap">
							<div className="plist">
								{
									categoryList && categoryList.length > 0 && categoryList.map((item, idx) => {
										if (!item.online) return null;

										const selected = formObj.selectedCategory.includes(item.code);
										return (
											<div className={`item ${selected && 'selected' || ''}`} key={idx} onClick={() => {
												console.log(item);
												formObj.selectedCategory = [];
												formObj.selectedCategory.push(item.code);
												setFormObj({ ...formObj });
												setCategoryFlag(false);
											}}>{item.label}</div>
										)
									})
								}
							</div>

						</div>
					</section>
				</section>
			}
			
		</Page>
	);
};

export default PageModel;
