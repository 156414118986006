/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';

import './index.less';

import IMG_BANNER_1 from './images/banner1.jpg';
import IMG_BANNER_2 from './images/banner2.jpg';
import IMG_BANNER_3 from './images/banner3.jpg';
import IMG_BIAOYU from './images/weath.png';
import IMG_ICON from './images/icon.png';
import IMG_ENTRANCE_1 from '../../common/images/entrance1.png';
import IMG_ENTRANCE_2 from '../../common/images/entrance2.png';
import IMG_ENTRANCE_3 from '../../common/images/entrance3.png';
import IMG_ENTRANCE_4 from '../../common/images/entrance4.png';
import IMG_ENTRANCE_COVER_1 from './images/cover-1.png';
import IMG_ENTRANCE_COVER_2 from './images/cover-2.png';
import IMG_ENTRANCE_COVER_3 from './images/cover-3.png';
import IMG_ENTRANCE_COVER_4 from './images/cover-4.png';
import IMG_ENTRANCE_COVER_5 from './images/cover-5.png';
import IMG_ENTRANCE_COVER_6 from './images/cover-6.png';

const PageModel = props => {

	const [ userInfo, setUserInfo ] = useState({});
	const [ text, setText] = useState('');//晴天 or 下雨
	const [ temp, setTemp] = useState('');//温度
	const [ windDir, setWindDir] = useState('');//风力

	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if(!result) return;
		setUserInfo(result.data);
	};

	const getWearth = async () => {
		let data = {
			location: '121.225084,31.246324'//经纬度直接先写死华新镇的
		}
		const result = await Request.get({
			url: '/weather/now',
			data: data
		});
		if (!result) return;
		console.log('当前的天气:', result);
		if(result.data){
			setText(result.data.text);
			setTemp(result.data.temp);
			setWindDir(result.data.windDir);
		}

	};

	useEffect(() => {
		console.log('--------------');
		getUserInfo();
		getWearth();

	}, [ 123 ]);

	const [ score, setScore ] = useState({ today: 0, total: 0, rest: 0 });
	const getScore = async () => {
		const result = await Request.get({
			url: '/user/score/total'
		});
		if(!result) return;

		setScore({ ...score, ...result.data });
	};
	useEffect(() => {
		getScore();
	}, [123]);

	return (
		<Page {...{ page: 'home', showFooter: true, title: '华新"管达人"' }} {...props}>
			<section className="home-container" style={{ minHeight: window.innerHeight - 50 }}>
				<div className='carousel1'>
					<div className='bannerimg' style={{ backgroundImage: `url(${IMG_BANNER_1})` }}></div>
					<div className='iconimg' style={{ backgroundImage: `url(${IMG_ICON})`}}></div>
					<div className='werth' style={{ backgroundImage: `url(${IMG_BIAOYU})` }}>
						<div className='werth1'>{temp}℃</div>
						<div className='werth2'>{text}</div>
						<div className='werth3'>{windDir}</div>
					</div>
				</div>
				{/* <div className="biaoyu-wrap" style={{ backgroundImage: `url(${IMG_BIAOYU})` }} /> */}
				{/* <Carousel className="carousel1" showArrows={false} infiniteLoop={true} showIndicators={false} showStatus={false}>
					<div className="carousel1-item" style={{ backgroundImage: `url(${IMG_BANNER_1})` }} />
					<div className="carousel1-item" style={{ backgroundImage: `url(${IMG_BANNER_2})` }} />
					<div className="carousel1-item" style={{ backgroundImage: `url(${IMG_BANNER_3})` }} />
				</Carousel> */}
				<div className="title-wrap">
					{/* <span className="big">{userInfo && userInfo.nickname}</span>
					<span className="small">剩余积分<em>{score.rest}</em>分</span>
					<span className="more" onClick={() => props.history.push('/user/score')}>更多明细></span> */}
				</div>
				<div className="entrances2">
					{
						[
							{ icon: IMG_ENTRANCE_3, cover: IMG_ENTRANCE_COVER_1, bgColor: '#BBE9FF', label: '热线事件', link: '/user/hot' },
							{ icon: IMG_ENTRANCE_3, cover: IMG_ENTRANCE_COVER_2, bgColor: '#BBE9FF', label: '网格化事件', link: '/user/report' },
							{ icon: IMG_ENTRANCE_3, cover: IMG_ENTRANCE_COVER_3, bgColor: '#BBE9FF', label: '操作指南', link: '/guider' },
							{ icon: IMG_ENTRANCE_1, cover: IMG_ENTRANCE_COVER_4, bgColor: '#FDEFEE', label: '上报记录', link: '/user/reports' },
							{ icon: IMG_ENTRANCE_2, cover: IMG_ENTRANCE_COVER_5, bgColor: '#FEFDE8', label: '市民留言', link: '/message/list' },
							{ icon: IMG_ENTRANCE_4, cover: IMG_ENTRANCE_COVER_6, bgColor: '#E4E8F5', label: '积分兑换', link: '/store' },
							// { icon: IMG_ENTRANCE_4, cover: IMG_ENTRANCE_COVER_6, bgColor: '#E4E8F5', label: '我的信息', link: '/user' }
						].map((item, idx) => {
							return <div className={`item${item.isBlock && ' block' || ''}`} style={item.style} key={idx} onClick={() => {
								if(item.link) {
									props.history.push(item.link);
								}
							}}>
								<div className="cover" style={{ backgroundImage: `url(${item.cover})` }} />
								{/*<img className="cover" src={item.icon} />*/}
								{/*<span>{item.label}</span>*/}
							</div>
						})
					}
				</div>
			</section>
		</Page>
	);
};

export default PageModel;
