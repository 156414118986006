/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../../common/request';
import * as Utils from '../../../common/utils';
import * as Const from '../../../common/const';
import * as AMap from '../../../common/amap';
import Page from '../../../components/page';
import Button from '../../../components/button';

import './index.less';

import IMG_CATEGORY from '../../../common/images/category.png';
import IMG_POI from '../../../common/images/poi.png';
import IMG_TRIAL from '../../../common/images/trial.png';
import ImageCompressor from "js-image-compressor";

const PageModel = props => {
	const [ formObj, setFormObj ] = useState({ covers: ['', '', ''] });
	const category = Utils.getSearchByKey('category');
	const submitHandle = async () => {
		const { id } = props.match.params;
		if(!id) {
			Utils._alert('案件信息不存在～');
			return;
		}
		if(!formObj.result) {
			Utils._alert('请输入结果描述信息/驳回理由');
			return;
		}

		// return;
		const _data = {
			...formObj,
			id,
		};
		if(category == 'chuli') {
			if(!formObj.covers || formObj.covers.length <= 0 || (formObj.covers && !formObj.covers[0])) {
				Utils._alert('请上传处理照片');
				return;
			}

			_data.status = 41;
			if(formObj && formObj.covers && formObj.covers[0]) {
				_data.images = formObj.covers;
			}
		}

		const result = await Request.post({
			url: '/problem/worker/deal/do',
			data: _data
		});
		if(!result) return;

		Utils._alert('问题处理成功');
		window.history.go(-1);
	};
	const uploadHandle = async (e,  idx) => {
		const selectedFile = e.target.files[0];
		let tokenResult = await Request.uploadToken();
		const selectedFileCompress = await Request.compressHelp(selectedFile,selectedFile.name);

		const resultUpload = await Request.uploadOss(tokenResult,selectedFileCompress);
		if(!resultUpload){
			Utils._alert('图片上传异常，result:'+resultUpload);
			return;
		}
		// const formData = new FormData();
		// formData.append('file', selectedFile);
		// const result = await Request.uploadFetch({
		// 	url: '/problem/upload',
		// 	method:'POST',
		// 	data: formData
		// });
		// if(!result) return;
		// const src = result.data.src;
		const src = resultUpload.name;
		formObj.covers[idx] = src;
		setFormObj({...formObj});
	};

	return (
		<Page title="问题处理">
			<section className="worker-report-deal-container">
				{
					category == 'bohui' && <div className="bohui-type">
						<span className="bohui-type-title">请选择驳回理由</span>
						<div className="bohui-type-list">
							{
								[
									{ label: '退单', status: 51 },
									{ label: '延期', status: 52 },
									{ label: '申诉', status: 53 },
								].map((item, idx) => <div className={`item ${formObj.status == item.status && 'selected'}`} onClick={() => {
									setFormObj({
										...formObj,
										status: item.status
									});
								}}><em /><label>{item.label}</label></div>)
							}
						</div>
					</div>
				}
				{
					formObj.status == 52 && <div className="bohui-type">
						<span className="bohui-type-title">请选择延期时间</span>
						<input className="bohui-delay-time" type="date" placeholder="请选择延迟时间"  onChange={e => {
							let delayExpired = e.target.value;
							delayExpired = delayExpired.replace(/\//ig, '-');
							setFormObj({
								...formObj,
								delayExpired
							});
						}} />
					</div>
				}
				<div className="textarea-wrap">
					<textarea placeholder={({
						chuli: '请输入问题处理的结果反馈信息（*必填）',
						bohui: '请输入驳回理由（*必填）'
					})[category]}  onChange={e => {
						setFormObj({
							...formObj,
							result: e.target.value
						});
					}} />
				</div>
				{
					category == 'chuli' && <div className="picture-wrapper">
						<span className="picture-wrap-title">请上传1-3张能体现处理结果的照片（*必填）</span>
						<div className="picture-wrap">
							{
								formObj && formObj.covers && formObj.covers.length > 0 && formObj.covers.map((item, idx) => {
									if(idx >= 3) return null;

									return (
										<div className="item" key={idx}>
											{ item && <img src={Const.imgPrefix + item} /> }
											{ item && <div className="close" onClick={() => {
												const covers = [ ...formObj.covers ];
												covers.splice(idx, 1);

												for(let i = 0, _len = 3 - covers.length; i < _len; i++) {
													covers.push('');
												}

												setFormObj({ ...formObj, covers});
											}} /> }
											<input type="file" accept="image/*" onChange={(e) => {
												uploadHandle(e, idx);
											}} />
										</div>
									)
								})
							}
						</div>
					</div>
				}
				<div className="button-wrap"><Button style={{ margin: 15 }} onClick={submitHandle} /></div>
			</section>
		</Page>
	);
};

export default PageModel;
