/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import './index.less';

import * as Utils from '../../common/utils';

import IMG_FOOTER_HOME from '../../common/images/home.png';
import IMG_FOOTER_HOME_SELECTED from '../../common/images/home-selected.png';
import IMG_FOOTER_STORE from '../../common/images/my.png';
import IMG_FOOTER_STORE_SELECTED from '../../common/images/my-selected.png';
import IMG_FOOTER_ADD from '../../common/images/add.png';
import Button from "../button";
import * as Request from "../../common/request";
import * as Const from "../../common/const";
import IMG_CATEGORY from "../../common/images/category.png";
import IMG_TRIAL from "../../common/images/trial.png";

const textProps = {
	content: 'Ant Design Mobile',
};

const CompModel = props => {
	const { page, showFooter = false, title = '华新"管达人"', style = {} } = props;
	
	
	useEffect(() => {
		Utils.setTitle(title);
	}, [123]);

	const [ userInfoFlag, setUserInfoFlag ] = useState(false);
	const [ departmentFlag, setDepartmentFlag ] = useState(false);
	const [ userInfoFormObj, setUserInfoFormObj ] = useState({});
	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if(!result) return;
	
		console.log('当前用户的未读消息有:', result.data?.num);
		window.localStorage.setItem("num", result.data?.num);
		if(!result.data?.department) {
			setUserInfoFlag(true);
		}
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);
	const submitHandle = async () => {
		console.log('userInfoFormObj', userInfoFormObj);
		if(!userInfoFormObj.department) {
			Utils._alert('请选择所在村居');
			return;
		}

		const result = await Request.post({
			url: '/user/set',
			data: userInfoFormObj
		});
		if(!result) return;
		Utils._alert('信息已完善');
		setUserInfoFlag(false);

		window.location.reload();
	};

	return (
		<div className="page-container" style={style}>
			
			{ props.children }
			{
				!!showFooter && <div className="page-footer">
					<div className={`pfooter-item${page == 'home' ? ' selected' : ''}`} onClick={() => props.history.push('/')}><div className="cover" style={{ backgroundImage: `url(${page == 'home' ? IMG_FOOTER_HOME_SELECTED : IMG_FOOTER_HOME})` }} /><span>首页</span></div>
					{/* <div className="pfooter-item" /> */}
					<div className={`pfooter-item${page == 'user' ? ' selected' : ''}`} onClick={() => props.history.push('/user')}>
						<div className="cover" style={{ backgroundImage: `url(${page == 'user' ? IMG_FOOTER_STORE_SELECTED : IMG_FOOTER_STORE})` }} >
							{window.localStorage.getItem("num") > 0 && <div className='bage'>{window.localStorage.getItem("num")}</div>}
						</div>
						<span>我的</span>
						{/* <div className='bage'>1</div> */}
					</div>
					{/* <div className="pfooter-add" onClick={() => {
						props.history.push('/user/report');
					}} /> */}
				</div>
			}

			{
				!!userInfoFlag && <div className="userinfo-fulfill-container">
					<div className="userinfo-fulfill-wrapper">
						<div className="title-wrap">立即完善信息，获赠30积分</div>
						<div className="category-wrap" onClick={() => {
							setDepartmentFlag(true);
						}}>
							<div className="label"><img src={IMG_CATEGORY} /><span>所在村居</span></div>
							<div className="value"><span style={{ marginLeft: 10, marginRight: 10 }}>{userInfoFormObj.department || '选择村居'}</span></div>
							<img className="trial" src={IMG_TRIAL} />
						</div>
						<div className="category-wrap">
							<div className="label"><img src={IMG_CATEGORY} /><span>手机号码</span></div>
							<div className="value"><input maxLength={11} placeholder="请输入手机号码" onChange={e => {
								setUserInfoFormObj({ ...userInfoFormObj, mobile: e.target.value });
							}} /></div>
						</div>

						<div className="button-wrap"><Button style={{margin: 15}} onClick={submitHandle}/></div>
					</div>
				</div>
			}

			{
				departmentFlag && <section className="category-container">
					<section className="category-wrap">
						<div className="title">
							<span>选择村居</span>
							<div className="close" onClick={() => { setDepartmentFlag(false); }} />
						</div>
						<div className="list-wrap">
							<div className="clist">
								{
									Const.departmentMap && Const.departmentMap.length > 0 && Const.departmentMap.map((item, idx) => {
										const selected = item == userInfoFormObj.department;
										return (
											<div className={`item ${selected && 'selected' || ''}`} key={idx} onClick={() => {
												setUserInfoFormObj({ ...userInfoFormObj, department: item });
												setDepartmentFlag(false);
											}}>{item}</div>
										)
									})
								}
							</div>
						</div>
					</section>
				</section>
			}
		</div>
	);
};

export default CompModel;
