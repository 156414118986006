/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../../common/request';
import * as Utils from '../../../common/utils';
import * as Const from '../../../common/const';
import Page from '../../../components/page';
import Button from '../../../components/button';

import './index.less';

import IMG_BANNER from './images/bg1.jpg';
import IMG_AVATAR_DEFAULT from '../../../common/images/avatar-default.png';
import IMG_ENTRANCE_1 from '../../../common/images/entrance1.png';
import IMG_ENTRANCE_2 from '../../../common/images/entrance2.png';
import IMG_ENTRANCE_3 from '../../../common/images/entrance3.png';
import IMG_ENTRANCE_4 from '../../../common/images/entrance4.png';

const PageModel = props => {
	const [ userInfo, setUserInfo ] = useState({});
	const getUserInfo = async () => {
		const result = await Request.get({
			url: '/user/get'
		});
		if(!result) return;
		setUserInfo(result.data);
	};
	useEffect(() => {
		getUserInfo();
	}, [ 123 ]);

	const [ flag, setFlag ] = useState(false);
	const [ tempObj, setTempObj ] = useState({});
	const submitHandle = async () => {
		if(!tempObj.mobile) {
			Utils._alert('手机号码不能为空哦');
			return;
		}

		const result = await Request.post({
			url: '/user/mobile',
			data: {
				mobile: tempObj.mobile
			}
		});
		if(!result) return;
		Utils._alert('手机号码更新成功');
		setFlag(false);
		delete tempObj.mobile;
		setTempObj(tempObj);
		getUserInfo();
	};

	return (
		<Page {...{ title: '华新"管达人"' }} {...props}>
			<section className="worker-home-container">
				<div className="banner-wrap" style={{ backgroundImage: `url(${IMG_BANNER})` }} />
				<div className="userinfo">
					<div className="cover" style={{ backgroundImage: `url(${userInfo && userInfo.cover || IMG_AVATAR_DEFAULT})` }} />
					<div className="info-detail">
						<span className="nickname">{userInfo.nickname}</span>
						<span>{userInfo.mobile || '暂无'}</span>
					</div>
					<span className="edit" onClick={() => {
						setTempObj(userInfo);
						setFlag(true);
					}}>修改信息</span>
				</div>
				<div className="entrances">
					{
						[
							{ icon: 'icon-chuli', label: '问题处理', link: '/worker/reports' },
							{ icon: 'icon-fasong', label: '上报问题', link: '/user/report' },
						].map((item, idx) => {
							return (
								<div className="item" key={idx} onClick={() => {
									if(item.link) {
										props.history.push(item.link);
									}
								}}><em className={`iconfont ${item.icon}`} />{item.label}</div>
							)
						})
					}
				</div>
			</section>

			{
				!!flag && <section className="self-modal-container">
					<section className="self-modal-wrap">
						<div className="title">编辑个人信息<div className="close" onClick={() => {
							setFlag(false);
							delete tempObj.mobile;
							setTempObj(tempObj);
						}} /></div>
						<div className="input-wrap"><input placeholder="输入有效手机号码" defaultValue={tempObj.mobile || undefined} maxLength="11" onChange={e => {
							setTempObj({
								...tempObj,
								mobile: e.target.value
							});
						}} /></div>
						<Button disabled={!tempObj.mobile || tempObj.mobile.length != 11} onClick={submitHandle} />
					</section>
				</section>
			}
		</Page>
	);
};

export default PageModel;
