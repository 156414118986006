/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';

import './index.less';

import IMG_COMMINGSOON from './images/commingsoon.png';

const PageModel = props => {
	return (
		<Page {...{ title: '即将上线' }} {...props}>
			<section className="commingsoon-container">
				<img src={IMG_COMMINGSOON} />
			</section>
		</Page>
	);
};

export default PageModel;