/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';

import './index.less';

import IMG_ZAN from '../../common/images/zan.png';
import IMG_ZAN_SELECTED from '../../common/images/zan-selected.png';
import IMG_READ_COUNT from '../../common/images/read-count.png';
import IMG_POI from '../../common/images/poi.png';
import IMG_AVATAR_DEFAULT from '../../common/images/avatar-default.png';

moment.locale('zh-cn');

const CONST_TABS = [
	{ label: '精选留言', type: 'all' },
	{ label: '我的留言', type: 'me', msg: window.localStorage.getItem("num") },
];

const PageModel = props => {
	const [ tab, setTab ] = useState({});
	useEffect(() => {
		const type = Utils.getSearchByKey('type');
		let target = CONST_TABS.filter(item => item.type == type);
		target = target && target[0] || CONST_TABS[0];
		setTab(target);
	}, [ window.location.href ]);

	const [ list, setList ] = useState([]);
	const getList = async () => {
		let url = '/user/message/list';
		if (tab.type == 'all') {
			url = '/message/list';
		}
		const result = await Request.get({
			url
		});
		if(!result) return;

		setList(result.data.list);
	};
	useEffect(() => {
		getList();
	}, [ tab ]);

	const likeHandle = async (id) => {
		const result = await Request.post({
			url: '/user/message/like',
			data: { id }
		});
		if(!result) return;

		Utils._alert('点赞成功');
		getList();
	};

	return (
		<Page title="留言板">
			<section className="msglist-container">
				<div className="tabs">
					{
						[
							{ label: '精选留言', type: 'all' },
							{ label: '我的留言', type: 'me', msg: window.localStorage.getItem("num") },
						].map((item, idx) => {
							return (
								<div className={`item${ item.type == tab.type ? ' selected' : '' }`} key={idx} onClick={() => {
									props.history.replace(`/message/list?type=${item.type}`)
								}}>{item.label} {item.msg > 0 && <div className='bagediv'>{item.msg}</div>}</div>
							)
						})
					}
				</div>
				<div className="msglist-wrap" style={{ minHeight: window.innerHeight - 34 }}>
					{
						list && list.length > 0 && list.map((item, idx) => {
							const userInfo = item.userInfo || {};
							const userCover = userInfo && userInfo.cover || IMG_AVATAR_DEFAULT;
							return (
								<div className="item" key={idx}>
									<div className="userinfo">
										<div className="cover" style={{ backgroundImage: `url(${userCover})` }} />
										<div className="detail">
											<span className="name">{userInfo.nickname}</span>
											<span>{moment(item.createdAt).fromNow()}</span>
										</div>
									</div>
									<div className="content">{item.content}</div>
									<div className="mfooter">
										<span>{item.createdAt && moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss')}</span>
										<div className="msg-aciton" onClick={() => {
											likeHandle(item.id);
										}}><img src={item.hasLike ? IMG_ZAN_SELECTED : IMG_ZAN} /><span>点赞 {item.likeCount}</span></div>
										{/*<div className="msg-aciton"><img src={IMG_READ_COUNT} /><span>流量量 68</span></div>*/}
									</div>
									{
										item.reply && <div className="contents">回复:{item.reply}</div>
									}
									{
										item.reply && <span className="spans">{item.updatedAt && moment(item.updatedAt).format('YYYY/MM/DD HH:mm:ss')}</span>
									}
								</div>
							)
						})
					}
				</div>

				<div className="button-wrap" onClick={() => {
					props.history.push(`/user/message`);
				}}><div className="button-main">我要留言</div></div>
			</section>
		</Page>
	);
};

export default PageModel;
