/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Page from '../../components/page';
import Button from '../../components/button';

import './index.less';

import IMG_CATEGORY from '../../common/images/category.png';
import IMG_POI from '../../common/images/poi.png';
import IMG_TRIAL from '../../common/images/trial.png';

const PageModel = props => {
	const [ data, setData ] = useState({});
	const submitHandle = async () => {
		const result = await Request.post({
			url: '/message/add',
			data
		});
		if(!result) return;

		Utils._alert('发布成功');
		setData({});
		window.history.go(-1);
	};

	return (
		<Page title="我要留言">
			<section className="message-container" style={{ minHeight: window.innerHeight }}>
				<div className="message-title">留言</div>
				<div className="textarea-wrap">
					<textarea placeholder="请输入想要说的话" onChange={e => {
						setData({
							...data,
							content: e.target.value
						})
					}} />
				</div>

				<div className="button-wrap">
					<Button
						style={{ margin: 15 }}
						disabled={!data.content}
						onClick={submitHandle}
					/>
				</div>
			</section>
		</Page>
	);
};

export default PageModel;