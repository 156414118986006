/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './index.less';

const CompModel = props => {
	return (
		<div className={`button-main${props.disabled && ' disabled' || ''}`} style={props.style} onClick={() => {
			if(props.disabled) return;

			props.onClick && typeof props.onClick == 'function' && props.onClick();
		}}>{props.btnText || '提交'}</div>
	);
};

export default CompModel;