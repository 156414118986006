/**
 * Created by jimmy on 2019/11/8.
 */

export const env = window.location.host.indexOf('yeekin.cn') > -1 ? 'product' : 'dev';

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api/v1';
// export const imgPrefix = env == 'product' ? '/' : 'http://127.0.0.1:44999/';
export const imgPrefix = 'https://little-butler.oss-cn-shanghai.aliyuncs.com/';

export const adminTypes = { 1: '超级管理员', 2: '普通管理员', 3: '游客'/* 游客只能浏览大盘数据 */ };

export const problemStatusMap = {
  11: '待受理',
  12: '已作废',
  21: '待立案',
  22: '不立案',
  31: '待派遣',
  32: '再派遣',
  33: '待处理',
  41: '待结案',
  42: '已结案',
  51: '退单审核中',
  52: '延期审核中',
  53: '申诉审核中'
};
export const problemStatusDisplayMap = {
  11: '待受理',
  12: '不在受理范围',
  21: '处理中',
  22: '不立案',
  31: '处理中',
  32: '处理中',
  33: '处理中',
  40: '处理中',
  41: '处理中',
  42: '已结案',
  51: '处理中',
  52: '处理中',
  53: '处理中'
};
export const departmentMap = [
  '城运中心', '北新村','徐谢村','朱长村','白马塘村','嵩山村','杨家庄村','火星村','新谊村','周浜村','马阳村','坚强村','秀龙村','陆象村','淮海村','叙南村','叙中村','新木桥村','华益村','凌家村',
  '华府社区居委','新丰社区居委','凤溪居委','西郊半岛社区居委','宝龙社区居委','春江社区居委','瑞和锦庭社区居委','星尚湾社区居委','悦欣社区居委','金瑞苑社区居委','华腾社区居委','华新居委',
  '虹桥运管大居','工业园区','城管中队','华新镇规保办','新益物业','乐洁物业','华新保洁公司','绿化公司','社发办','河长制办公室','房管所','经发办','农服中心','安管中心','城建公司','城市服务公司中心村',
  '华悦筹备组', '悦澜筹备组', '华强筹备组'
];

export const appId = env == 'product' ? 'wx774b75ea13e1e376' : 'wx774b75ea13e1e376';
